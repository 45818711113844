/**@jsx jsx */
import { navigate } from "gatsby";
import { useEffect } from "react";
import { Button, jsx } from "theme-ui";
import { Layout } from "../components/layout/main-layout";

export default function Thanks() {
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 5000);
  }, []);
  return (
    <Layout>
      <div
        sx={{
          display: "grid",
          placeItems: "center",
          mt: "64px",
          minHeight: ["calc(100vh - 128px)", null, null, "calc(100vh - 176px)"],
          overflow: "hidden",
          textAlign: "center",
        }}
      >
        <div>
          <h1>Gracias por contáctarnos</h1>
          <p>En breve te regresaremos al home</p>
          <Button
            onClick={() => {
              navigate("/");
            }}
          >
            Ir al Home
          </Button>
        </div>
      </div>
    </Layout>
  );
}
